import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import Para from './Para'

type LabeledTextFieldProps = {
  label: string,
  onChange: (text: string) => void,
  placeholder?: string
  value: string
  password?: string
  visible?: true
  isValid?: string
}

const LabeledTextField = ({ label, onChange, placeholder = '', value, password = false, visible = true, isValid = true}: LabeledTextFieldProps) => {
	if(visible == true)
	{
  return (
    <View>
      <Para>{label}</Para>
		  { isValid ? 
            <TextInput value={value} placeholder={placeholder} secureTextEntry={password} style={styles.textInput} onChangeText={onChange}/> :
            <TextInput value={value} placeholder={placeholder} secureTextEntry={password} style={styles.textInputInvalid} onChangeText={onChange}/>
		  }
    </View>
  )
	}
	else
	{
		return null;
	}
}

export default LabeledTextField

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    fontSize: 16,
    height: 55,
    paddingHorizontal: 4,
	backgroundColor: '#ffffff',
  },
  textInputInvalid: {
    borderWidth: 2,
    fontSize: 16,
    height: 55,
    paddingHorizontal: 4,
	borderColor: 'red',
	backgroundColor: '#ffffff',
  }
})
