export default {
  dark: '#333',
  primaryPurple: '#312e49',
  violet: '#4524bc',
  red: '#cf0e18',
  teal: '#26b4b0',
  black: '#000',
  white: '#fff',
  skyblue: '#99cff3',
  green: '#17cc65',
  lightgreen: '#b5c75b',
  darkgrey: '#666666',
  lightgrey: '#dadada',
}
