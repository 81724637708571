import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Linking } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import { Card, Tile } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Summary(props) {
	const [into_text, setIntoText] = useState("loading...");
	const [last_updated, setLastUpdated] = useState(null);
	const [last_updated_days_ago, setLastUpdatedDaysAgo] = useState(null);
	const [walk_summary, setWalkSummary] = useState(null);
	const [connected, setConnected] = useState(true);
	const [role, setRole] = useState("3");
	const [party, setParty] = useState("you");
	
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
	console.log(a)
	console.log(b)
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

	useEffect(() => {
		GetData()
	}, [])
	
	const GetData = () => {
		AsyncStorage.getItem('user').then((value) => {
			AsyncStorage.getItem('role').then((role) => {
			console.log(value)
			setRole(role)
			
			if (role == "1")
			{
				setParty("your members")
			}
			const user = value
			const api = new Api();
			api.GetUserLatestResponse(user, function done(data) {
				console.log(data)
				if (data != "")
				{
					setLastUpdated(data.dateTimeAdded)
					setLastUpdatedDaysAgo(dateDiffInDays(new Date(data.dateTimeAdded), new Date()))
				}
				else
				{
					setLastUpdated("none")
				}
				GetWalkSummary(value)

				}, function notFound() {
					setConnected(true)
					setLastUpdated("none")
					console.log("2")
					GetWalkSummary(value)
				}, function notConnected() {
					setConnected(false)
					console.log("3")
					setLastUpdated("none")
				});
			})
		})
		
	}
	
	const GetWalkSummary = (user) => {
			const api = new Api();
		api.GetWalkSummary(user, function done(data) {
			setConnected(true)
			if (data != "")
			{
				setWalkSummary(data)
			}
			else
			{
				setWalkSummary("none")
			}
				
		}, function notFound() {
				setLastUpdated("none")
		});
	}

	const clickSummary = () => {
		props.GoToScreen('weeklist')
	}

	const clickWalkDistance = () => {
		props.ShowChart('1')
	}
	
	const clickCycleDistance = () => {
		props.ShowChart('5')
	}
	
	const clickHelpCentre = () => {
		props.GoToScreen('helpcentre')
	}

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Summary</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					{last_updated == null || role == "1" ? null :
				<TouchableOpacity onPress={() => clickSummary()}> 
					<Card containerStyle={styles.tileContainer}>
					{last_updated == 'none' ?
					<View>
					
					{connected == false ?
					  <View>
					  <Para centerAlign={true} color={'#ffffff'} >
							There was a problem. Please check your internet connection.
					  </Para>
					  <DefaultButton onPress={GetData} text={"Retry"}/>
					  </View>
					:
					  <View>
						  <Para centerAlign={true} color={'#ffffff'}>
							You haven't submitted any data yet.
						  </Para>
						  <Para centerAlign={true} color={'#ffffff'}>
							Click here to submit data.
						  </Para>
					  </View>
					}
					  
					</View>
					  :
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						You last submitted data:
					  </Para>
					  <Para centerAlign={true} bold={true} color={last_updated_days_ago < 8 ? Colors.white : Colors.red} fontSize={30}>
						  {last_updated_days_ago == 0 ? <Text>Today</Text> : 
							<Text>{last_updated_days_ago} days ago</Text>
						  }
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here to submit more data.
					  </Para>
					</View>
					}
					</Card>
				</TouchableOpacity>
					}
					{walk_summary == null ? null :
				<View>
				<TouchableOpacity onPress={() => clickWalkDistance()}> 
					<Card containerStyle={styles.tileContainer}>
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						So far {party} have walked
					  </Para>
					  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
						  {walk_summary.distanceWalked} miles
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here for more details.
					  </Para>
					</View>
					</Card>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => clickCycleDistance()}> 
					<Card containerStyle={styles.tileContainer}>
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						So far {party} have cycled
					  </Para>
					  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
						  {walk_summary.distanceCycled} miles
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here for more details.
					  </Para>
					</View>
					</Card>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.ShowChart('3')}> 
					<Card containerStyle={styles.tileContainer}>
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						So far {party} have burned
					  </Para>
					  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
						  {walk_summary.caloriesBurned} calories
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here for more details.
					  </Para>
					</View>
					</Card>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.ShowChart('2')}> 
					<Card containerStyle={styles.tileContainer}>
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						So far {party} have saved
					  </Para>
					  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
						  {walk_summary.co2Saved} kg in CO2 emmissions
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here for more details.
					  </Para>
					</View>
					</Card>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.ShowChart('4')}> 
					<Card containerStyle={styles.tileContainer}>
					<View>
					  <Para centerAlign={true} color={'#ffffff'}>
						So far {party} have saved
					  </Para>
					  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
						  &pound;{walk_summary.moneySaved}
					  </Para>
					  <Para centerAlign={true} color={'#ffffff'}>
						Click here for more details.
					  </Para>
					</View>
					</Card>
				</TouchableOpacity>
				</View>
					
					}
					
				<TouchableOpacity onPress={() => clickHelpCentre()}> 
					<Card containerStyle={styles.tileContainer}>
						<View>
						  <Para centerAlign={true} bold={true} color={Colors.white} fontSize={30}>
							  Resource Centre
						  </Para>
						  <Para centerAlign={true} color={'#ffffff'}>
							Click here for useful tools and information to help get you started.
						  </Para>
						</View>
					</Card>
				</TouchableOpacity>
				<Card containerStyle={styles.tileContainerWhite}>
					<View>
					  <Para centerAlign={true}>
						Please follow the team on social media on Facebook <Text style={styles.linkstyle} onPress={() => Linking.openURL('https://www.facebook.com/WCCSafeActiveTravel')}>@WCCSafeActiveTravel</Text> and Twitter <Text style={styles.linkstyle} onPress={() => Linking.openURL('https://twitter.com/WCCSafe_Active')}>@WCCSafe_Active</Text>.
					  </Para>
					</View>
				</Card>
			  <VerticalSpace height={20} />
			  
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}
