import React, { useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity, Platform } from 'react-native'
import {Picker} from '@react-native-picker/picker';
import Para from './Para'

import VerticalSpace from '../components/VerticalSpace'
import DefaultButton from '../components/DefaultButton'
import Modal from "react-native-modal";
import Styles from '../constants/Styles'

type LabeledDropDownWithTooltip2Props = {
  label: string,
  onChange: (text: string) => void,
  password?: string
  value: string
  items: Any
  tooltip: string
  tooltipid: string
  isValid?: string
}

const LabeledDropDownWithTooltip = ({ label, onChange, placeholder = '', value, password = false, items, tooltip='', tooltipid='', isValid = true}: LabeledTextFieldProps) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleModal = () => setIsModalVisible(() => !isModalVisible);
	
  return (
    <View>
      <Para>{label} <TouchableOpacity onPress={handleModal}><Image style={styles.tooltipIconSmall} data-tip={tooltipid} data-for={tooltipid} source={require('../assets/questionmark.png')}></Image></TouchableOpacity></Para>
		  { isValid ? 
		  <View style={styles.pickerwrapper}>
			<Picker
			  style={styles.pickerbox}
			  value={value}
			  selectedValue={value}
			  items={items}
				onValueChange={(itemValue, itemIndex) => onChange(itemValue)} >
			  <Picker.Item label={'Select an item'} value={''} style={styles.pickerItem} />
				{items.map(d => {
				  return (<Picker.Item label={d.label} value={d.value} style={styles.pickerItem} />);
				})}
				
			  </Picker>
			</View>
				:
		  <View style={styles.pickerwrapperinvalid}>
			<Picker
			  style={styles.pickerbox}
			  value={value}
			  selectedValue={value}
			  items={items}
				onValueChange={(itemValue, itemIndex) => onChange(itemValue)} >
			  <Picker.Item label={'Select an item'} value={''} style={styles.pickerItem} />
				{items.map(d => {
				  return (<Picker.Item label={d.label} value={d.value} style={styles.pickerItem} />);
				})}
				
			  </Picker>
			</View>
		  }
	  <Modal isVisible={isModalVisible} >
		<View style={Styles.modal}>
			<Para>{tooltip}</Para>
			<VerticalSpace height={20} />
		  <DefaultButton text="Close" onPress={handleModal} />
		  </View>
	  </Modal>
    </View>
  )
}

export default LabeledDropDownWithTooltip

const pickerstyle = StyleSheet.create({
	border: 'none',
}
)

const pickerstyleInvalid = StyleSheet.create({
	border: 'none',
}
)

const styles = {
	tooltipIconSmall: {
		height: 20,
		width: 20,
	},
	pickerwrapper: {
		borderWidth: 1,
		backgroundColor: '#ffffff',
		borderColor: '#000000',
	},
	pickerbox: {
		fontSize: 16,
		borderWidth: 0,
		...Platform.select({
		  web: {
			height: 55
		  },
		  android: {
			height: 55
		  },
		}),
	},
	pickerwrapperinvalid: {
		borderWidth: 2,
		backgroundColor: '#ffffff',
		borderColor: '#ff0000',
	},
	pickerItem: {
		...Platform.select({
		  web: {
			height: 55
		  },
		  android: {
			height: 55
		  },
		}),
	}
}

