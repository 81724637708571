import React, { useEffect, useState } from "react";
import { StyleSheet, View, ImageBackground, Image } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import LabeledDropDown from '../components/LabeledDropDown'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import DefaultButton from '../components/FloatingDefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Moment from 'moment';
import Table from '../components/Table3'
import AsyncStorage from '@react-native-async-storage/async-storage'

import styles from '../constants/Styles'
import Colors from '../constants/Colors'
import waitingimg from '../assets/loading.gif';

export default function WeekList(props) {
	const [weekly_records_list, setWeeklyRecordsList] = useState(null);
	const [into_text, setIntoText] = useState("");
	const [records_found, setRecordsFound] = useState(false);

	useEffect(() => {
		GetData()
	}, [])
	
	const GetData = () => {
		AsyncStorage.getItem('user').then((value) => {
			const user = value
			const api = new Api();
			api.getUserListWeeklyRecords(user, function done(data) {
				setWeeklyRecordsList(data)
				setRecordsFound(true)
				if (data.length == 0)
				{
					setIntoText("Keep a record of your journeys to work. Each week we will ask you to input information about your previous weeks journeys here.")
				}
				else
				{
					setIntoText("Listed below are details for each week since you joined the scheme. Those weeks highlighted in orange require your attention");
				}
					
			}, function notFound() {
				setIntoText("Keep a record of your journeys to work. Each week we will ask you to input information about your previous weeks journeys here.")
				setRecordsFound(true)
			}, function notConnected() {
				setIntoText("There was a problem. Please check your internet connection.")
				setRecordsFound(true)
			});
		})		
	}
	
	const handleSubmit = () => {
		props.GoToScreen('summary')
	}

   const columns = React.useMemo(
     () => [
		{
			Header: "Week",
			Cell: (props) => (
			  <div>
			  {Moment(props.row.original.week_beginning).format("DD MMM")}
				</div>
			)
		},
		{
			Header: "Mon",
			accessor: "monday",
			Cell: ( props ) => (
			<div>
				{props.row.original.monday == '' || props.row.original.monday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "monday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "monday")}><div style={{textAlign: 'center'}}>{props.row.original.monday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Tue",
			accessor: "tuesday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(1, "days") > Moment(new Date()) ? null :
props.row.original.tuesday == '' || props.row.original.tuesday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "tuesday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "tuesday")}><div style={{textAlign: 'center'}}>{props.row.original.tuesday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Wed",
			accessor: "wednesday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(2, "days") > Moment(new Date()) ? null : props.row.original.wednesday == '' || props.row.original.wednesday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "wednesday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "wednesday")}><div style={{textAlign: 'center'}}>{props.row.original.wednesday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Thu",
			accessor: "thursday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(3, "days") > Moment(new Date()) ? null :props.row.original.thursday == '' || props.row.original.thursday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "thursday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "thursday")}><div style={{textAlign: 'center'}}>{props.row.original.thursday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Fri",
			accessor: "friday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(4, "days") > Moment(new Date()) ? null :props.row.original.friday == '' || props.row.original.friday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "friday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "friday")}><div style={{textAlign: 'center'}}>{props.row.original.friday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Sat",
			accessor: "saturday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(5, "days") > Moment(new Date()) ? null :props.row.original.saturday == '' || props.row.original.saturday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "saturday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "saturday")}><div style={{textAlign: 'center'}}>{props.row.original.saturday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		},
		{
			Header: "Sun",
			accessor: "sunday",
			Cell: ( props ) => (
			<div>
				{Moment(props.row.original.week_beginning).add(6, "days") > Moment(new Date()) ? null :props.row.original.sunday == '' || props.row.original.sunday == null ? <div style={{textAlign: 'center', backgroundColor: '#FFA500'}}><a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "sunday")}>Edit</a></div> : <a href="#" style={{textAlign: 'center'}} onClick={e => handleEdit(props.row.original, "sunday")}><div style={{textAlign: 'center'}}>{props.row.original.sunday.map(({ id, travelTypeId, travelDistance }) => (<div key={id} style={{padding:5}}> <ImageBackground source={travelTypeId == 1 ? require('../assets/car-icon.png') : travelTypeId == 2 ? require('../assets/cycle-icon.png') : travelTypeId == 3 ? require('../assets/walk-icon.png') : require('../assets/bus-icon.png')} resizeMode="cover" style={{ flex: 1 }} imageStyle={{ height: 20, width: 20 }} >{travelDistance}</ImageBackground> </div>))}</div></a>}
			</div>
			)
		}
     ],
     []
   )

	function handleEdit(week, day) {
		props.setWeek(week, day);
	}
	
	function getWeekFromId(id) {
		if (id == 1)
			return "../assets/car-icon.png"
		else if (id == 2)
			return "../assets/Cycle-icon.png"
		else if (id == 3)
			return "../assets/walk-icon.png"
		else if (id == 4)
			return "../assets/bus-icon.png"
		else if (id == 5)
			return "Other"
	}

  return (
	<View style={{flex: 1, backgroundColor: Colors.skyblue}}>
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar logout={props.logout} goHome={props.goHome} user={props.user} ></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={34} >Your journeys</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
					<Para>{into_text}</Para>
				<VerticalSpace height={20} />
					{records_found == false ? <View style={styles.imageWrapper} >
					<Image style={styles.waitingimgstyle} alt="Waiting..." source={waitingimg}></Image>
					</View>
 :
					weekly_records_list == null ? null : <Table data={weekly_records_list} columns={columns} pages={false} hideSearch={true} handleEdit={handleEdit} />
					}
				<VerticalSpace height={40} />
			</View>
		  </View>
		  <View style={styles.innerContainer}>
				<DefaultButton onPress={handleSubmit} text={"Done"}/>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>
  </View>


  );
}

