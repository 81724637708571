import React, { useEffect, useState } from "react";
import { ImageBackground, StyleSheet, View, Text, Linking } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'

import Colors from '../constants/Colors'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Styles from '../constants/Styles'

interface WelcomeProps {
  navigation: any
  register: Function
  login: Function
}


export default function Welcome ({ navigation, register, login }: WelcomeProps) {
  return (
    <KeyboardAwareScrollView style={Styles.outerContainer}>
	  <View style={Styles.innerContainer}>
		<NavigationBar navigation={navigation} logout={login}></NavigationBar>
	  </View>
	  <View style={Styles.container, Styles.greenStripe}>
		<View style={[Styles.innerContainer, Styles.container]}>
			<Heading fontsize={34} >Welcome</Heading>
			<VerticalSpace height={10} />
			</View>
	  </View>
      <View style={[Styles.container, Styles.jojWelcome]}>
			<View style={[Styles.innerContainer, Styles.container]}>
				<VerticalSpace height={20} />
				<View><Para bold={true}>Hello, and welcome to the JustOneJourney Walking App!</Para>
				<Para>Increasing the number of active journeys you make is a great way to save money and improve your health and well-being whilst helping reduce your carbon footprint. This app will support you travel more actively, more easily by helping you set personal goals and monitor progress.</Para>
				<Para>This app is also an important part of the evaluation of Warwickshire County Councils JustOneJourney active travel project.  Therefore, any information you provide through this App will be used to assess the success of the project.  Whilst the information you share will be held in confidence, it will be used to produce publicly available reports and papers.  However, it will not be possible to identify any individual in any documentation we release. A frequently asked questions paper about the research can be viewed <Text style={Styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/faq')}>here</Text>.  You can also see our full privacy policy <Text style={Styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/privacy')}>here</Text>.</Para></View>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => register()} text={'Register'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => login()} text={'Log in'} />
				<VerticalSpace height={20} />
		</View>
    </View>
      <View style={[Styles.acWelcome]}>
				<View><ImageBackground style={Styles.backgroundImage} source={require('../assets/street_cycling.jpg')}>
				<View style={[Styles.innerContainer, Styles.container, Styles.acWelcomePara]}>
				<Para bold={true}>Hello, and welcome to the aktiv8Change App!</Para>
				<Para>Increasing the number of active journeys you make is a great way to save money and improve your health and well-being whilst helping reduce your carbon footprint. This app will support you travel more actively, more easily by helping you set personal goals and monitor progress.</Para>
				<Para>This app is also an important part of the evaluation of activ8Change active travel projects.  Therefore, any information you provide through this App will be used to assess the success of the projects.  Whilst the information you share will be held in confidence, it will be used to produce publicly available reports and papers.  However, it will not be possible to identify any individual in any documentation we release. A frequently asked questions paper about the research can be viewed <Text style={Styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/faq')}>here</Text>.  You can also see our full privacy policy <Text style={Styles.linkstyle} onPress={() => Linking.openURL('http://aktiv8change.edrivingsolutions.com/privacy')}>here</Text>.</Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => register()} text={'Register'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => login()} text={'Log in'} />
				<VerticalSpace height={20} />
				</View>
				</ImageBackground>
				</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}


